import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons'

export default class Audio extends React.Component {
  constructor(props) {
    super(props)
    this.state = { play: false }
    this.play = this.play.bind(this)
  }
  play() {
    if (this.state.play) {
      this.setState({ play: false })
      this.audio.pause()
    } else {
      this.setState({ play: true })
      this.audio.play()
    }
  }
  render() {
    return (
      <div className={this.props.className}>
        <FontAwesomeIcon
          onClick={this.play}
          icon={!this.state.play ? faPlayCircle : faPauseCircle}
        />
        <audio
          src={`https://www.neusong.com/${this.props.audio}`}
          ref={(audio) => {
            this.audio = audio
          }}
        >
          Your browser doesn&#39;t support the html5 audio tag
          <track
            src="captions_en.vtt"
            kind="captions"
            srcLang="en"
            label="english_captions"
          />
        </audio>
      </div>
    )
  }
}
