import React from 'react'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Songs from '../../components/Songs'

function SearchFor({ data }) {
  return (
    <Layout>
      <div className="sm:mx-16">
        <Songs data={data} />
      </div>
    </Layout>
  )
}

SearchFor.propTypes = {
  data: PropTypes.object,
}

export default SearchFor

export const SearchForQuery = graphql`
  query SearchForQuery {
    allSongsJson(sort: { fields: page, order: ASC }) {
      edges {
        node {
          id
          page
          playtime
          filesize
          filename
          title
          tune
        }
      }
    }
  }
`
