import React from 'react'
import DataTable from 'react-data-table-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Audio from '../components/Audio'

const Button = ({ onClear }) => {
  return (
    <div>
      <div className="relative flex mt-1 shadow rounded-md">
        <button
          className="relative block h-10 p-1 text-lg rounded-md shadow-sm form-input focus:outline-none bg-light-grey"
          onClick={onClear}
        >
          Clear Search
        </button>
      </div>
    </div>
  )
}

const FilterPage = ({ onClick, className, filterPage, onFilter, onClear }) => {
  return (
    <div className={className}>
      <div className="relative flex mt-1 shadow-md rounded-r-md">
        <input
          id="search-by-page"
          type="text"
          aria-label="Search by page"
          placeholder="Jump to Page"
          className="relative block w-full p-2 mt-1 rounded-r-md shadow-sm form-input bg-light-grey focus:outline-none sm:text-sm sm:leading-5"
          value={filterPage}
          onChange={onFilter}
          onClick={onClick}
        />
        <button
          className="relative block pr-4 mt-1 text-lg rounded-r-md shadow-sm form-input focus:outline-none bg-light-grey"
          onClick={onClear}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  )
}

const FilterHymnTune = ({
  onClick,
  filterHymnTune,
  className,
  onFilter,
  onClear,
}) => (
  <div className={className}>
    <div className="relative flex mt-1 shadow-md rounded-r-md">
      <input
        className="relative block w-full p-2 mt-1 rounded-r-md focus:outline-none shadow-sm form-input bg-light-grey sm:text-sm sm:leading-5"
        id="search-by-tune"
        aria-label="Search by tune"
        type="text"
        placeholder="Search by Hymntune"
        value={filterHymnTune}
        onChange={onFilter}
        onClick={onClick}
      />
      <button
        className="relative block pr-4 mt-1 text-lg rounded-r-md shadow-sm focus:outline-none form-input bg-light-grey"
        onClick={onClear}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  </div>
)

const FilterTitle = ({ className, filterTitle, onFilter, onClear }) => (
  <div className={className}>
    <div className="relative flex mt-1 shadow-md rounded-r-md">
      <input
        id="search-by-title"
        aria-label="Search by Title"
        className="relative block w-full p-2 mt-1 focus:outline-none rounded-r-md shadow-sm form-input bg-light-grey sm:text-sm sm:leading-5"
        type="text"
        placeholder="Search by Title Keywords(s)"
        value={filterTitle}
        onChange={onFilter}
      />
      <button
        className="relative block pr-4 mt-1 text-lg focus:outline-none rounded-r-md shadow-sm form-input bg-light-grey"
        onClick={onClear}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  </div>
)

const customStyles = {
  rows: {
    style: {
      minHeight: '80px', // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: '2rem',
      fontWeight: '400',
    },
  },
  cells: {
    style: {
      fontSize: '1rem',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
}

const columns = [
  {
    name: 'Page',
    selector: 'page',
    center: true,
    sortFunction: (a, b) => a.page - b.page,
    hide: 'sm',
    sortable: true,
  },
  {
    name: 'Title',
    selector: 'title',
    center: true,
    sortable: true,
  },
  {
    name: 'Hymntune',
    selector: 'tune',
    center: true,
    sortable: true,
  },
  {
    name: '',
    selector: 'music',
    maxWidth: '200px',
    center: true,
    cell: (row) => {
      return (
        <div className="block -ml-32 sm:ml-0">
          <a
            className="block p-2 text-black rounded-full hover:text-dark-blue transition ease-in-out duration-300 focus:outline-none"
            href={`https://www.neusong.com/music/accompaniments/${row.music}.zip`}
          >
            <FontAwesomeIcon className="color-black" icon={faDownload} />
          </a>
          <Audio
            audio={`music/accompaniments/${encodeURI(row.music)}`}
            className="block hover:text-dark-blue transition ease-in-out duration-300 focus:outline-none transform translate-x-2"
          />
        </div>
      )
    },
  },
]

const BasicTable = (props) => {
  const { edges: songs } = props.data.allSongsJson

  let dataArray = songs.map(({ node: song }) => {
    return {
      title: song.title,
      page: song.page,
      filename: song.filename,
      tune: song.tune,
      music: song.filename,
    }
  })

  const [filterPage, setFilterPage] = React.useState('')
  const [filterHymnTune, setFilterHymnTune] = React.useState('')
  const [filterTitle, setFilterTitle] = React.useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  )
  const filteredItems = dataArray.filter((item) => {
    if (filterHymnTune) {
      // partial match
      return item.tune.toLowerCase().includes(filterHymnTune.toLowerCase())
    } else if (filterTitle) {
      // partial match
      return item.title.toLowerCase().includes(filterTitle.toLowerCase())
    } else if (filterPage) {
      // exact match
      return item.page.toLowerCase() === filterPage.toLowerCase()
    } else {
      return item
    }
  })

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterPage) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterPage('')
      } else if (filterHymnTune) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterHymnTune('')
      } else if (filterTitle) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterTitle('')
      }
    }
    const clearAll = () => {
      setFilterPage('')
      setFilterHymnTune('')
      setFilterTitle('')
      setResetPaginationToggle(!resetPaginationToggle)
    }

    return (
      <div className="w-full mr-6 sm:flex md:-ml-6 lg:ml-4 xl:ml-16 space-x-2 md:space-x-8 xl:space-x-16">
        <FilterPage
          onFilter={(e) => {
            setFilterPage(e.target.value)
          }}
          onClear={handleClear}
          filterPage={filterPage}
          className="hidden w-full sm:w-1/4 sm:block"
        />
        <FilterTitle
          onFilter={(e) => {
            setFilterTitle(e.target.value)
          }}
          onClear={handleClear}
          filterTitle={filterTitle}
          className="w-full sm:w-1/4"
        />
        <FilterHymnTune
          onFilter={(e) => setFilterHymnTune(e.target.value)}
          onClear={handleClear}
          filterHymnTune={filterHymnTune}
          className="w-full sm:w-1/4"
        />
        <Button onClear={clearAll} />
      </div>
    )
  }, [filterPage, filterHymnTune, filterTitle, resetPaginationToggle])

  return (
    <>
      <div style={{ marginBottom: '5em' }}>
        <DataTable
          title=""
          columns={columns}
          data={filteredItems}
          defaultSortField="page"
          fixedHeader={true}
          highlightOnHover={true}
          pagination={true}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 75]}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          striped={true}
          customStyles={customStyles}
          subHeaderAlign="left"
          sortIcon={<FontAwesomeIcon className="ml-2" icon={faArrowUp} />}
          keyField={filteredItems.id}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
        />
      </div>
    </>
  )
}

export default BasicTable
